@font-face {
  font-family: 'NeueHelvetica';
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url('/fonts/NeueHelvetica55Roman.woff2') format('woff2');
}
@font-face {
  font-family: 'NeueHelvetica';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  src: url('/fonts/NeueHelvetica85Heavy.woff2') format('woff2');
}
@font-face {
  font-family: 'NeueHelvetica';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url('/fonts/NeueHelvetica65Medium.woff2') format('woff2');
}

/* @font-face {
  font-family: 'NettoW05';
  src: url('/fonts/NettoW05-Regular.woff2') format('woff2'),
    url('/fonts/NettoW05-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NettoW05';
  src: url('/fonts/NettoW05-Light.woff2') format('woff2'),
    url('/fonts/NettoW05-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NettoW05';
  src: url('/fonts/NettoW05-Bold.woff2') format('woff2'),
    url('/fonts/NettoW05-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
} */
